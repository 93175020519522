nav{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  padding: 2%;
  align-items: center;
  text-decoration: none;
  font-family: Futura;
}

#nav-container{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#navRight{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#navLeft {
  font-size: 30px;
}

a {
  color: rgb(0, 0, 0);
  text-decoration: none;
}

#postLink{
  margin-right: 20px;
}

#nav-button {
  padding: 10% 13% 10% 13%;
  width: 50px;
  background-color: white;
  border: 1px solid rgb(205, 201, 201);
  border-radius: 3px;
}
@media (max-width:500px){
  #nav-button {
    padding: 5% 5% 5% 5%;
    width: 75px;
    height: 100%;
    background-color: white;
    border: 1px solid rgb(205, 201, 201);
    border-radius: 3px;
    margin: 0;
  }
}