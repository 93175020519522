#homePage{
  font-family: "Roboto", sans-serif;
  width: 100%;
  height: 100%;
  display: column;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: futura;
}

#top-nav{
  margin-bottom: 2%;
  animation: fadein 2s;
  position: fixed;
  bottom: 0;
  font-size: 20px;
  padding-left: 2%;
}
