#postsContainer{
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  padding: 2% 20% 2% 20%;
  margin: 0px;
  align-items: center;
  justify-content: center;
  animation: fadein 2s;
}

@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}

@media (max-width: 990px){
    #postsContainer{
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        padding: 5% 10% 10% 10%;
        margin: 0px;
        align-items: center;
        justify-content: center;
        animation: fadein 2s;
    }
}