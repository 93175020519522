#formContainer {
    display: flex;
    justify-content: center;
    margin: .5em auto;
    font-family: Futura;
}

#formTitle {
    font-size: 24px;
    margin-bottom: 30px;
    margin-top: 100px;
}

form {
    display: flex;
    flex-direction: column;
    width: 500px;
    height: 510px;
    text-align: center;
    font-family: Futura;
}

input {
    width: 300px;
    height: 30px;
    padding: 2%;
    border: grey solid 1px;
    border-radius: 5px;
    margin-bottom: 15px;
    font-family: Futura;
    background-color: rgb(245, 248, 238);
}

#info-button {
    width: 320px;
    height: 45px;
    border: grey solid 1px;
    padding: 2%;
    border-radius: 5px;
    margin-top: 25px;
    font-family: Futura;
    background-color: #DFFF00
}

button:hover{
    background-color: #cddd64
}
@media (max-width: 430px) {
    #formContainer{
      display: flex;
      justify-content: center;
    }

    #formTitle {
        font-size: 24px;
        margin-bottom: 30px;
        margin-top: 130px;
    }

    form{
        margin-right: 3%;
        margin-bottom: 3%;
        border-radius: 3%;
        width: 300px;
        height: 500px;
        position: relative;
        text-align: center;
        overflow: hidden;
      }
      input {
        width: 250px;
        height: 30px;
        padding: 2%;
        border: grey solid 1px;
        border-radius: 5px;
        margin-bottom: 15px;
        font-family: Futura;
        background-color: rgb(245, 248, 238);
    }
    
    #info-button {
        width: 262px;
        height: 45px;
        border: grey solid 1px;
        padding: 2%;
        border-radius: 5px;
        margin-top: 25px;
        font-family: Futura;
        background-color: #DFFF00
    }
    }