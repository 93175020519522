#postContainer{
  /* margin-right: 5%;
  margin-bottom: 3%; */
  width: 300px;
  height: 300px;
  position: relative;
  text-align: center;
  overflow: hidden;
  background-color: #DFFF00;
}

#postImage{
  max-width: inherit;
  max-height: inherit;
  height: inherit;
  width: inherit;
  object-fit: cover;
}

#postCityCountry, #timeStamp {
  margin-bottom: 2%;
}

.imageOverlay {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  background:rgba(0, 0, 0, 0.6);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0; 
  transition: opacity 0.25s;
}

.imageOverlay > * {
  transform: translateY(20px);
  transition: transform 0.25s;
}

.imageOverlay:hover {
  opacity: 1;
}

.imageOverlay:hover > * {
  transform: translate(0)
}

@media (max-width: 990px) {
  #postContainer{
    width: 300px;
    height: 300px;
    position: relative;
    text-align: center;
    overflow: hidden;
    background-color: #DFFF00;
  }

}