#infoPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2% 30% 5% 30%;
    text-align: justify;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#video{
    margin-bottom: 3%;
}

.infoTextDiv{
    margin-bottom: 20px;
}

#resources{
    margin-top: 20px;
}

@media (max-width:990px){
    #infoPage {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 4% 4% 8% 4%;
        text-align: justify;
    }
}